<template>

  <v-fade-transition>
  <v-container class="pa-0" v-show="pendingWorkouts.length > 0">
    <!-- Initial view -->
    <v-card class="initial-view ma-4" elevation="2" @click="showList = true">
      <v-card-text class="text-center pt-6">

        <v-avatar size="64" color="amber">
          <v-icon x-large color="secondary">mdi-dumbbell</v-icon>
        </v-avatar>
        <div class="text-h6  mb-2 mt-4">
          {{ pendingWorkouts.length }} {{ pendingWorkouts.length > 1 ? 'entrenamientos esperan' : 'entrenamiento espera' }}  tu opinión
        </div>
        <div class="text-caption grey--text text--darken-1 mb-6">
          Último entrenamiento: {{ lastWorkoutDate }}
        </div>
        
        <v-btn
          
          @click="showList = true"
          :loading="loading"
          elevation="2"
          x-large
          class="rounded-lg"
        >
          <v-icon left color="amber">mdi-star</v-icon>
          Calificar ahora
        </v-btn>
      </v-card-text>
    </v-card>

    <!-- Workouts list modal -->
    <v-dialog v-model="showList" max-width="500" transition="dialog-bottom-transition">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text px-4 py-3">
          <v-btn icon dark @click="showList = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="ml-2">Calificar entrenamientos</span>
        </v-card-title>

        <v-card-text class="pa-4">
          <!-- Rate all button -->
          <v-card outlined class="mb-6 rounded-lg">
            <v-card-text class="text-center py-4">
              <div class="text-subtitle-1 grey--text text--darken-1 mb-3">
                ¿Todo estuvo excelente?
              </div>
              <v-btn
                x-large
                @click="rateAllFiveStars"
                :loading="submittingAll"
                :disabled="pendingWorkouts.length === 0"
                elevation="2"
                class="rounded-lg"
              >
                <v-icon left color="amber">mdi-star</v-icon>
                Calificar todos
              </v-btn>
            </v-card-text>
          </v-card>

          <!-- List of workouts -->
          <div class="text-subtitle-1 grey--text text--darken-1 mb-3 text-center">
            Calificar individualmente
          </div>
          <v-list class="rounded-lg" outlined>

            <template v-for="(workout, index) in pendingWorkouts">
              <v-list-item   :key="workout.id"
                      
                        @click="rateWorkout(workout)"
                        class="rounded hover-elevation">
              <v-list-item-avatar>
                <v-avatar color="primary" size="40">
                  <v-icon dark>mdi-dumbbell</v-icon>
                </v-avatar>
              </v-list-item-avatar>
              
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">
                  {{ formatDate(workout.date) }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="workout.plan_name" class="caption">
                  {{ workout.plan_name }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon color="amber darken-2">
                  <v-icon>mdi-star-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < pendingWorkouts.length - 1"></v-divider>

            </template>
            
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Rating Dialog -->
    <v-dialog v-model="ratingDialog" max-width="500" transition="dialog-bottom-transition">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text px-4 py-3">
          <v-btn icon dark @click="ratingDialog = false">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-icon left color="amber" class="ml-2">mdi-star</v-icon>
          <span class="ml-2">Calificar entrenamiento</span>
        </v-card-title>


        <v-card-text class="pa-6">
          <div class="text-center mb-6">
            <div class="text-subtitle-1 grey--text mb-3">
              ¿Qué te pareció este entrenamiento?
            </div>
            <v-rating
              v-model="selectedRating"
              color="amber"
              background-color="amber lighten-3"
              hover
              size="42"
            ></v-rating>
          </div>
          
          <v-textarea
            v-model="comment"
            label="Comentarios (opcional)"
            rows="3"
            outlined
            rounded
            hide-details
            class="rounded-lg mx-auto px-4"
          ></v-textarea>
        </v-card-text>

        <v-divider></v-divider>
        
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn  large  @click="ratingDialog = false"             class="rounded-lg mr-2"
          >
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn  large
            color="primary"
            :loading="submitting"
            :disabled="!selectedRating"
            @click="submitRating"
            class="rounded-lg"
            elevation="2"
          >
            Enviar calificación
          </v-btn>
          <v-spacer></v-spacer>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</v-fade-transition>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import moment from 'moment';

export default {
  name: 'PendingRatings',
  
  data() {
    return {
      pendingWorkouts: [],
      ratingDialog: false,
      selectedWorkout: null,
      selectedRating: 0,
      comment: '',
      submitting: false,
      loading: true,
      showList: false,
      submittingAll: false,
    };
  },

  computed: {
    lastWorkoutDate() {
      if (this.pendingWorkouts.length === 0) return '';
      return this.formatDate(this.pendingWorkouts[0].date);
    }
  },

  methods: {
    async fetchPendingRatings() {
      this.loading = true;
      const db = getFirestore();
      const workoutsRef = collection(db, 'workouts');
      
      try {
        const q = query(
          workoutsRef,
          where('user_id', '==', this.$store.state.Auth.token.claims.user_id),
          where('rating', '==', 0)
        );

        const snapshot = await getDocs(q);
        this.pendingWorkouts = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort by date descending
        this.pendingWorkouts.sort((a, b) => b.date.toMillis() - a.date.toMillis());
      } catch (error) {
        console.error('Error fetching pending ratings:', error);
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'No se pudieron cargar las calificaciones pendientes',
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
      return moment(date.toDate()).locale('es').format('D [de] MMMM [en] YYYY');
    },

    rateWorkout(workout) {
      this.selectedWorkout = workout;
      this.selectedRating = 0;
      this.comment = '';
      this.ratingDialog = true;
    },

    async submitRating() {
      if (!this.selectedRating) return;
      
      this.submitting = true;
      const db = getFirestore();
      
      try {
        const workoutRef = doc(db, 'workouts', this.selectedWorkout.id);
        await updateDoc(workoutRef, {
          rating: this.selectedRating,
          comment: this.comment || null,
          seen:false
        });

        this.$notify({
          group: 'feedback',
          title: 'Éxito',
          text: 'Gracias por tu calificación',
          type: 'success'
        });

        // Remove rated workout from list
        this.pendingWorkouts = this.pendingWorkouts.filter(
          w => w.id !== this.selectedWorkout.id
        );
        
        this.ratingDialog = false;
      } catch (error) {
        console.error('Error submitting rating:', error);
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'No se pudo guardar la calificación',
          type: 'error'
        });
      } finally {
        this.submitting = false;
      }
    },

    async rateAllFiveStars() {
      this.submittingAll = true;
      const db = getFirestore();
      
      try {
        const updatePromises = this.pendingWorkouts.map(workout => {
          const workoutRef = doc(db, 'workouts', workout.id);
          return updateDoc(workoutRef, {
            rating: 5,
            comment: null,
            seen: true
          });
        });

        await Promise.all(updatePromises);

        this.$notify({
          group: 'feedback',
          title: 'Éxito',
          text: 'Todos los entrenamientos han sido calificados con 5 estrellas',
          type: 'success'
        });

        this.pendingWorkouts = [];
        this.showList = false;
      } catch (error) {
        console.error('Error rating all workouts:', error);
        this.$notify({
          group: 'feedback',
          title: 'Error',
          text: 'No se pudieron calificar todos los entrenamientos',
          type: 'error'
        });
      } finally {
        this.submittingAll = false;
      }
    },
  },

  mounted() {
    this.fetchPendingRatings();
  }
};
</script>

<style scoped>
.initial-view {
  border-radius: 16px !important;
}

.hover-elevation {
  transition: all 0.2s ease-in-out;
}


.v-list-item {
  cursor: pointer;
  margin-bottom: 8px;
  border-radius: 8px;
}

.v-dialog {
  border-radius: 16px;
}
</style> 